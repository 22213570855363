import {createApi} from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import store from '../store';
import _ from 'lodash';
import {trackEvent} from '../util';

// ENG-1737 - Added axios interceptor to track API requests and responses
axios.interceptors.request.use(request => {
  request.customData = {startTime: new Date().getTime()};
  return request;
});

axios.interceptors.response.use(response => {
  trackEvent('API Request Completed', _.pick(response.config, ['method', 'url']), new Date().getTime() - response.config.customData.startTime);
  return response;
}, error => {
  trackEvent('API Request Failed', {status: _.get(error, 'response.status', 'UNKNOWN'), ..._.pick(error.config, ['method', 'url'])}, new Date().getTime() - error.config.customData.startTime);
  return Promise.reject(error);
});

const axiosBaseQuery =
  ({baseUrl} = {baseUrl: ''}) =>
    async ({url, method = 'GET', data, params, headers}) => {
      const {session} = store.getState();
      const _headers = {
        'x-inscope-org': session.currentOrg.orgId,
        ...headers,
      }
      if (!_.isNil(session.accessToken)) {
        _headers.Authorization = `Bearer ${session.accessToken}`;
      }
      try {
        const result = await axios({
          url: `${baseUrl}${url}`,
          method,
          data,
          params,
          headers: _headers,
        })
        return {data: result.data}
      } catch (axiosError) {
        const err = axiosError
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      }
    }

export const excelApiSlice = createApi({
  reducerPath: 'excelApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_EXCEL_API_URL,
  }),
  endpoints: builder => ({
    getLinkedItem: builder.query({
      query: ({itemId}) => ({
        url: `/api/linking/item/${itemId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  endpoints,
  useGetLinkedItemQuery,
} = excelApiSlice;
