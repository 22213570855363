// Important: The data param is bound to the handsontable instance.
// All functions below that manipulate the table data mutate this variable directly,
// otherwise changes will not take effect.
import {trackEvent} from '../../../util';

export const simulatePasteEvent = (hotInstance, linkedData) => {
  return new Promise((resolve) => {
    const _startTime = new Date().getTime();
    if (!hotInstance) {
      trackEvent('Simulate Paste Event Completed', {status: 'HOT_INSTANCE_NOT_FOUND'}, new Date().getTime() - _startTime);
      resolve();
      return;
    }

    if (!linkedData || !linkedData.record || !linkedData.record.recordValues) {
      trackEvent('Simulate Paste Event Completed', {status: 'LINKED_DATA_NOT_FOUND'}, new Date().getTime() - _startTime);
      resolve();
      return;
    }

    const pasteData = convertLinkedDataToTableFormat(linkedData);
    const pasteEvent = new ClipboardEvent('paste', {
      clipboardData: new DataTransfer(),
      bubbles: true,
      cancelable: true,
    });

    pasteEvent.clipboardData.setData('application/json', pasteData);

    const coords = getStartingLinkedDataCoords(linkedData);
    const hotCoords = [{startRow: coords[0], startCol: coords[1]}];

    const customPasteHandler = (event) => {
      hotInstance.rootElement.removeEventListener('paste', customPasteHandler);

      // Temporarily disable read-only
      const originalReadOnly = hotInstance.getSettings().readOnly;
      hotInstance.updateSettings({readOnly: false});

      // Get current row count and calculate new row count
      const currentRowCount = hotInstance.countRows();
      const newRowCount = pasteData.length;

      // Remove excess rows if linkedData now has less.
      if (currentRowCount > newRowCount) {
        hotInstance.alter('remove_row', newRowCount, currentRowCount - newRowCount);
      }

      // Run pasting algorithm's and load data
      hotInstance.runHooks('beforePaste', pasteData, hotCoords);
      hotInstance.populateFromArray(coords[0], coords[1], pasteData);
      hotInstance.runHooks('afterPaste', pasteData, hotCoords);
      hotInstance.runHooks('afterChange', null, 'CopyPaste.paste');

      // Restore read-only
      hotInstance.updateSettings({readOnly: originalReadOnly});

      event.preventDefault();
      trackEvent('Simulate Paste Event Completed', {status: 'SUCCESS'}, new Date().getTime() - _startTime);
      resolve();
    };

    hotInstance.rootElement.addEventListener('paste', customPasteHandler);
    hotInstance.rootElement.dispatchEvent(pasteEvent);
  });
};

export const handlePaste = (data, coords, updateCellProperties, isLinked) => {
  if (data.length > 0) {
    handlePasteData(data, coords);
    handlePasteProperties(data, coords, updateCellProperties, isLinked);
  }
};

export const handlePasteCleanup = (data, coords, updateCellProperties, hotInstance, setMergedCells) => {
  const mergeCells = [];

  for (let row = 0; row < data.length; row++) {
    if (row === data.length - 1) { continue; }

    for (let col = 0; col < data[row].length; col++) {
      const cellMeta = hotInstance.getCellMeta(row, col);
      let updateProperties = {};

      if (data[row][col] === '' && cellMeta.format !== undefined) {
        updateProperties.format = null;
      }

      // clear the default total cells that existed prior to paste
      if (cellMeta.totalCell) {
        updateProperties = {
          ...updateProperties,
          totalCell: false,
          doubleBorderBottom: false,
          singleBorderTop: false,
          format: null,
        };
      }

      if (Object.keys(updateProperties).length > 0) {
        updateCellProperties(row, col, updateProperties);
      }

      // Calculate merged cells for manually pasted data.
      if (row === 0 && data[row][col] !== null && data[row][col] !== '') {
        let colspan = 1;

        // Check for colspan
        for (let c = col + 1; c < data[row].length && data[row][c] === null; c++) {
          colspan++;
        }

        if (colspan > 1) {
          mergeCells.push({
            row,
            col,
            rowspan: 1,
            colspan,
          });
        }
      }
    }
  }

  setMergedCells(mergeCells);
};

const handlePasteProperties = (data, coords, updateCellProperties, isLinked) => {
  const {startRow, startCol} = coords[0];

  // convert cells that contain dollar or percent symbols into corresponding
  // inscope cell formats
  const cellConditions = [
    {
      test: (value) => typeof value === 'string' && /%\s?$/.test(value),
      action: (value, row, col) => {
        const newValue = value.replace('%', '');
        if (!isLinked) updateCellProperties(startRow + row, startCol + col, {format: 'percent'});
        return newValue;
      },
    },
    {
      test: (value) => typeof value === 'string' && /^\s?\$/.test(value),
      action: (value, row, col) => {
        const newValue = value.replace('$', '');
        if (!isLinked) updateCellProperties(startRow + row, startCol + col, {format: 'dollar'});
        return newValue;
      },
    },
  ];

  for (let row = 0; row < data.length; row++) {
    for (let col = 0; col < data[row].length; col++) {
      let value = data[row][col];

      if (col !== 0 && typeof value === 'string') {
        value = value.trim();
        data[row][col] = value;
      }

      cellConditions.forEach(condition => {
        if (condition.test(value)) {
          data[row][col] = condition.action(value, row, col);
        }
      });

      // update merged header cells
      if (!isLinked && (row === 0 && startRow === 0 && value !== null && value !== '')) {
        updateCellProperties(startRow + row, startCol + col, {singleBorderBottom: true});
      }
    }
  }
};

const handlePasteData = (data) => {
  const valueColumns = identifyValueColumns(data);
  const dollarColumns = identifyDollarColumns(data);

  // remove columns that just contain dollar symbols
  dollarColumns.sort((a, b) => b - a).forEach(colIndex => {
    data.forEach(row => row.splice(colIndex, 1));
  });

  // if padding columns don't exist between value columns, add them
  insertMissingPaddingColumns(data, valueColumns);

  // merge all label columns into one column and add tabs
  const firstNonLabelColumnIndex = calculateFirstNonLabelColumnIndex(valueColumns, dollarColumns);
  if (firstNonLabelColumnIndex > 1) {
    mergeLabelColumns(data, firstNonLabelColumnIndex);
  }
};

const identifyValueColumns = (data) => {
  const valueColumns = [];

  for (let colIndex = 0; colIndex < data[0].length; colIndex++) {
    const column = data.map(row => row[colIndex]);
    const numericCount = column.filter(isNumber).length + column.filter(v => v?.trim() === '-').length;

    if ((numericCount / column.length >= 0.25) && colIndex !== 0) {
      valueColumns.push(colIndex);
    }
  }

  return valueColumns;
};

const identifyDollarColumns = (data) => {
  const dollarColumns = [];

  for (let colIndex = 0; colIndex < data[0].length; colIndex++) {
    const column = data.map(row => row[colIndex]);
    const hasDollar = column.some(cell => cell === '$');
    const allValid = column.every(cell => cell === '' || cell === '$');
    if (hasDollar && allValid) {
      dollarColumns.push(colIndex);
    }
  }

  return dollarColumns;
};

const mergeLabelColumns = (data, upToIndex) => {
  data.forEach(row => {
    let lastValueIndex = -1;
    for (let i = 0; i < upToIndex; i++) {
      if (row[i] !== '' && row[i] !== null) {
        lastValueIndex = i;
      }
    }

    let mergedValue = '';
    if (lastValueIndex !== -1) {
      mergedValue = '\t'.repeat(lastValueIndex) + row[lastValueIndex];
    }

    row.splice(0, upToIndex, mergedValue);
  });
};

const insertMissingPaddingColumns = (data, valueColumns) => {
  valueColumns.sort((a, b) => a - b);

  let i = 0;
  while (i < valueColumns.length - 1) {
    const currentColumnIndex = valueColumns[i];
    const nextColumnIndex = valueColumns[i + 1];

    if (nextColumnIndex - currentColumnIndex === 1) {
      const paddingColumnIndex = currentColumnIndex + 1;

      data.forEach(row => {
        const leftValue = row[currentColumnIndex];
        const rightValue = row[nextColumnIndex];

        if (leftValue === null || rightValue === null) {
          // nulls mean its a merged cell, insert nulls to retain colspan
          row.splice(paddingColumnIndex, 0, null);
        } else {
          row.splice(paddingColumnIndex, 0, '');
        }
      });

      // Adjust valueColumns indices to account for the inserted padding column
      for (let j = i + 1; j < valueColumns.length; j++) {
        valueColumns[j]++;
      }

      valueColumns.splice(i + 1, 0, paddingColumnIndex);
      i++;
    }

    i++;
  }
};

const calculateFirstNonLabelColumnIndex = (valueColumns, dollarColumns) => {
  if (valueColumns.length === 0 && dollarColumns.length === 0) {
    return -1;
  }

  const combinedColumns = [...valueColumns, ...dollarColumns];
  return Math.min(...combinedColumns);
}

const isNumber = (cell) => {
  const numberRegex = /\d+/g;
  return numberRegex.test(cell);
};

const convertLinkedDataToTableFormat = (linkedData) => {
  const recordValues = linkedData.record.recordValues;
  const result = [];

  for (const row of recordValues) {
    const processedRow = [];
    let i = 0;

    while (i < row.length) {
      const cell = row[i];
      const value = Array.isArray(cell.text) ? cell.text[0] : cell.text;

      if (cell.merged === true && cell.colSpan > 1) {
        processedRow.push(value.trim());

        // Fill the next colSpan - 1 columns with null to direcly mimic pasted data of clipboard
        for (let j = 1; j < cell.colSpan; j++) {
          processedRow.push(null);
        }

        i += cell.colSpan;
      } else {
        processedRow.push(
          typeof value === 'number'
            ? value.toString()
            : (typeof value === 'string' ? value.trim() : ''),
        );
        i++;
      }
    }

    result.push(processedRow);
  }

  return result;
};

const getStartingLinkedDataCoords = (linkedData) => {
  const recordValues = linkedData.record.recordValues;

  const isAlphaWord = (str) => {
    return /\w/.test(str);
  };

  for (let i = 0; i < recordValues.length; i++) {
    const value = recordValues[i][0].text[0].trim();

    if (isAlphaWord(value)) {
      return i <= 1 ? [1, 0] : [0, 0];
    }
  }

  return [0, 0];
};
