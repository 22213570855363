import {Node, ReactNodeViewRenderer} from '@tiptap/react';
import LinkedText from './LinkedText';

const LinkedTextNodeView = Node.create({
  name: 'linkedText',
  inline: true,
  group: 'inline',
  selectable: true,
  atom: true,

  addOptions () {
    return {
      isPrinting: false,
    };
  },

  addAttributes () {
    return {
      'linked-item-id': {
        default: null,
      },
      text: {
        default: '',
      },
      source: {
        default: '',
      },
      updatedAt: {
        default: '',
      },
      updatedBy: {
        default: '',
      },
    };
  },

  parseHTML () {
    return [
      {
        tag: 'linked-text',
      },
    ];
  },

  renderHTML ({HTMLAttributes}) {
    return ['linked-text', HTMLAttributes];
  },

  addNodeView () {
    return ReactNodeViewRenderer(LinkedText);
  },

  addCommands () {
    return {
      setLinkedText:
        (attributes) =>
          ({commands}) => {
            return commands.insertContent({
              type: this.name,
              attrs: attributes,
            });
          },
      unsetLinkedText:
        () =>
          ({state, dispatch}) => {
            const {selection, tr} = state;
            const {from, to} = selection;

            tr.removeMark(from, to, this.type);
            dispatch(tr);
            return true;
          },
    };
  },
});

export default LinkedTextNodeView;
