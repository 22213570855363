export const LINE_ITEM_TYPES = {
  BALANCE_SHEET: 'Balance Sheet',
  DIRECT_CASHFLOW: 'Direct Cashflow',
  INCOME_STATEMENT: 'Income Statement',
  INDIRECT_CASHFLOW: 'Indirect Cashflow',
  OPEX_MAPPING: 'Opex Mapping',
}

export const FSLI_TYPES = LINE_ITEM_TYPES;

export const REPORTS = {
  BALANCE_SHEET: 'Balance Sheet',
  DIRECT_CASHFLOW: 'Direct Cashflow',
  INDIRECT_CASHFLOW: 'Indirect Cashflow',
  INCOME_STATEMENT: 'Income Statement',
  INCOME_STATEMENT_GAAP: 'Income Statement (GAAP)',
  INCOME_STATEMENT_NON_GAAP: 'Income Statement (non-GAAP)',
}
export const INDENT_UNITS = 3;
export const TOTAL_FSLIS = [
  'Total',
  'Net cash provided by',
  'Cash, cash equivalents and restricted cash at end of the period',
  'Effect of exchange rate changes on cash and cash equivalents',
  'Gross Profit',
  'Total Operating Expenses',
  'Income From Operations',
  'Net Profit',
  'Net Profit (Loss)',
  'Income Before Income Taxes',
  'Total Liabilities',
  'Net increase in cash and cash equivalents and restricted cash',
  'Income (Loss) Before Income Taxes',
];
export const REPORTING_STANDARDS = {
  'NON-GAAP': 'Non GAAP',
  GAAP: 'GAAP',
}

export const REPORT_TYPES = {
  BALANCE_SHEET: {
    label: 'BS',
    title: 'Balance Sheet',
    depthCorrection: -1,
  },
  INCOME_STATEMENT_GAAP: {
    label: 'I/S(GAAP)',
    title: 'Income Statement',
    depthCorrection: -1,
  },
  INCOME_STATEMENT_NON_GAAP: {
    label: 'I/S(Non-GAAP)',
    title: 'Income Statement (non-GAAP)',
    depthCorrection: -1,
  },
  INDIRECT_CASHFLOW: {
    label: 'SCF(Indirect)',
    title: 'Cash Flow (Indirect)',
    depthCorrection: -2,
  },
  DIRECT_CASHFLOW: {
    label: 'SCF(Direct)',
    title: 'Cash Flow (Direct)',
    depthCorrection: -2,
  },
}

export const CREATE_FLOWS = {
  annualRollForward: 'ANNUAL_ROLL_FORWARD',
  annualBlankDocument: 'ANNUAL_BLANK_DOCUMENT',
  quarterly: 'QUARTERLY',
  other: 'OTHER',
}

export const CREATE_STEPS = {
  create: 'CREATE',
  processing: 'PROCESSING',
}

export const DOCUMENT_TYPES = {
  annualReport: 'ANNUAL',
  quarterlyReport: 'QUARTER',
  other: 'OTHER',
}

export const ROLL_FORWARD_STATUSES = {
  notStarted: 'NOT_STARTED',
  inProgress: 'IN_PROGRESS',
  completed: 'COMPLETED',
  failed: 'FAILED',
}
