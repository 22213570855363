import React, {useEffect} from 'react';
import {NodeViewWrapper} from '@tiptap/react';

import {Box, Skeleton, Tooltip, Typography} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TimeAgo from '../TimeAgo';

import {useInView} from 'react-intersection-observer';
import {useGetLinkedItemQuery} from '../../data/excelApi';

import PropTypes from 'prop-types';
import spacetime from 'spacetime';

const LinkedText = ({node, selected, updateAttributes, extension}) => {
  const {ref, inView} = useInView({triggerOnce: true});
  const linkedItemId = node.attrs['linked-item-id'];
  const isPrinting = extension.options.isPrinting;

  const {data: linkedItemData, isLoading: isLoadingLinkedData, isError} = useGetLinkedItemQuery({itemId: linkedItemId}, {
    skip: !inView || !linkedItemId,
  });

  useEffect(() => {
    if (linkedItemData && !isLoadingLinkedData && !isError) {
      updateAttributes({
        text: linkedItemData.record.recordValues[0][0].text[0]?.trim(),
        source: linkedItemData.record.label,
        updatedAt: linkedItemData.record.updatedAt,
        updatedBy: linkedItemData.record.updatedBy,
      });
    }
  }, [linkedItemData, isLoadingLinkedData, isError]);

  const baseStyles = {
    cursor: 'pointer',
  };

  const getBorderStyles = () => {
    const borderColor = isError ? '#F4613C' : '#50CDEB';

    if (selected) {
      return {
        border: `2px solid ${borderColor}`,
      };
    } else {
      return {
        borderBottom: `2px solid ${borderColor}`,
      };
    }
  };

  const linkedTextStyles = {...baseStyles, ...getBorderStyles()};

  const ErrorAlert = () => {
    return (
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        sx={{
          width: 32,
          height: 32,
          mr: 1,
          borderRadius: '8px',
          background: 'rgba(244, 97, 60, 0.15)',
        }}>
        <ErrorOutlineIcon sx={{color: '#F4613C', width: 16, height: 16}} />
      </Box>
    );
  }

  return (
    <NodeViewWrapper as="span" ref={ref} style={{whiteSpace: 'normal'}}>
      {(inView || isPrinting) && !isLoadingLinkedData
        ? (
            selected
              ? (
                <span
                  className="linked-text"
                  style={linkedTextStyles}
                  data-linked-item-id={linkedItemId}
                >
                  {node.attrs.text}
                </span>
                )
              : (
                <Tooltip
                  title={
                    <Box display='flex' flexDirection='row' alignItems='center'>
                      {isError && <ErrorAlert />}
                      <Box>
                        <Typography fontSize={12} fontWeight={500} color="#0F1E24">
                          {node.attrs.text?.length
                            ? isError
                              ? `Source link is broken: ${node.attrs.source}`
                              : `Linked source: ${node.attrs.source}`
                            : 'Data not found'}
                        </Typography>
                        {node.attrs.updatedAt && (
                          <Typography fontSize={11} color="#0F1E24">
                            Synced <TimeAgo timestamp={spacetime(node.attrs.updatedAt).epoch} /> by {node.attrs.updatedBy || 'N/A'}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  }
                  placement="top"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
                        px: 1.5,
                        py: 1,
                        maxWidth: 'none',
                      },
                    },
                  }}
                >
                  <span
                    className="linked-text"
                    style={linkedTextStyles}
                    data-linked-item-id={linkedItemId}
                  >
                    {node.attrs.text || '#error'}
                  </span>
                </Tooltip>
                )
          )
        : (
          <Skeleton
            height={22}
            width={60}
            style={{display: 'inline-block', verticalAlign: 'middle'}}
          />
          )
      }
    </NodeViewWrapper>
  );
};

LinkedText.propTypes = {
  node: PropTypes.object.isRequired,
  updateAttributes: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  extension: PropTypes.shape({
    options: PropTypes.shape({
      isPrinting: PropTypes.bool,
    }),
  }),
};

export default LinkedText;
