import React, {Component} from 'react';
import Loading from '../Loading';
import Error from '../Error';
import NoData from '../NoData';
import PropTypes from 'prop-types';
import {Box, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import ReportDetailsModal from './ConfiguredReportDetailsModal';
import {connect} from 'react-redux';
import {withLogger} from '../LoggingWrapper';
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';
import {triggerDownload, renderAmount, trackEvent} from '../../util';
import * as api from '../../util/api';
import {withRequiredAuthInfo} from '@propelauth/react';
import ReportTable from '../ReportTable';
import {APP_HEADER_HEIGHT, FOOTER_HEIGHT, GREEN_70, GREY_90, WHITE_100} from '../../App';
import TableRow from '@mui/material/TableRow';
import TableCell from '../TableCell';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import Decimal from 'decimal.js-light';
import {setHasDifference} from '../../data/reports';

const INITIAL_PLI_DETAILS = {
  pli: {},
  open: false,
}

export const enableHover = (row, report, flag, scfDrillDownFlag) => {
  if (!_.has(report, 'record.reportType')) {
    return false;
  }
  if (!scfDrillDownFlag && report.record.reportType === 'DIRECT_CASHFLOW') {
    return false;
  }
  return flag && _.includes(['BALANCE_SHEET', 'INCOME_STATEMENT_GAAP', 'INCOME_STATEMENT_NON_GAAP', 'DIRECT_CASHFLOW'], report.record.reportType) && (row.children.length === 0 || _.sumBy(row.children, _c => _c.config.hideLine ? 0 : 1) === 0) && !_.isNil(row.amount) && row.amount !== 0;
}

export class ConfiguredReportClass extends Component {
  constructor (props) {
    super(props);

    this.index = 0;

    this.state = {
      pliDetails: INITIAL_PLI_DETAILS,
      loadingTemplate: false,
    }
  }

  downloadFile = async _reportId => {
    const _startTime = new Date().getTime();
    this.setState({loadingTemplate: true});
    try {
      await triggerDownload(() => api.getFile(this.props.accessToken, this.props.currentOrg.orgId, _reportId), `${this.props.title} - ${this.props.period}.xlsx`);
      trackEvent('Download Report Completed', {reportId: _reportId}, new Date().getTime() - _startTime);
    } catch (_error) {
      console.error(_error);
      trackEvent('Download Report Failed', {reportId: _reportId}, new Date().getTime() - _startTime);
    } finally {
      this.setState({loadingTemplate: false});
    }
  }

  renderDownloadButton = () => {
    if (this.props.report.record.filledTemplateExcelKey && (!_.has(this.props.currentOrg, 'org_metadata.hideDownload') || this.props.currentOrg.org_metadata.hideDownload === false)) {
      return (
        <LoadingButton onClick={() => this.downloadFile(this.props.report.record.fileId)} endIcon={<DownloadIcon />} variant="contained" color={'darkGreen'} loading={this.state.loadingTemplate} >
          Download Workbook
        </LoadingButton>
      );
    }
  }

  onClose = () => {
    this.setState({pliDetails: INITIAL_PLI_DETAILS});
  }

  openPliDetails = (row) => {
    if (enableHover(row, this.props.report, this.props.flags.reportDetails, this.props.flags.reportDrillDownsScf)) {
      this.setState({pliDetails: {pli: row, open: true}});
    }
  }

  _getComparePeriod = (period) => {
    if (!this.props.reviewMode && !_.isNil(this.props.comparePeriod) && !_.isEmpty(this.props.comparePeriod)) {
      return {
        comparePeriod: this.props.reportingPeriodMap[this.props.comparePeriod],
        compareRecord: {},
      };
    } else if (this.props.reviewMode) {
      return {
        comparePeriod: period,
        compareRecord: this.props.report.compareRecord,
      }
    }
    return {
      comparePeriod: null,
      compareRecord: null,
    }
  }

  renderAmountCell = (amount, showCurrency, invertDisplayAmount, fontStyle) => {
    return (
      <Stack direction={'row'} justifyContent={'space-between'}>
        {showCurrency && (
          <Typography variant={'body1'} {...fontStyle}>$</Typography>
        )}
        {!showCurrency && (
          <Typography variant={'body1'} {...fontStyle} />
        )}
        <Typography variant={'body1'} {...fontStyle}>{renderAmount(amount, invertDisplayAmount)}</Typography>
      </Stack>
    )
  }

  renderRow = (row) => {
    if (row.config.hideLine || (new Decimal(row.amount).todp(0).toNumber() === 0 && !_.has(row, 'compareAmount')) || (new Decimal(row.amount).todp(0).toNumber() === 0 && _.has(row, 'compareAmount') && new Decimal(row.compareAmount).todp(0).toNumber() === 0)) {
      return null;
    }
    this.index += 1;
    const _borderStyle = {};
    if (row.config.showSingleLineHeader) {
      _borderStyle.borderTopWidth = 1;
      _borderStyle.borderColor = GREY_90;
    }
    if (row.config.showSingleLineFooter) {
      _borderStyle.borderBottomWidth = 1;
      _borderStyle.borderColor = GREY_90;
    } else if (row.config.showDoubleLineFooter) {
      _borderStyle.borderBottom = '3px double';
      _borderStyle.borderColor = GREY_90;
    }
    const _bgColor = this.index % 2 === 0 ? WHITE_100 : GREEN_70;
    const _fontStyle = {};
    if (row.config.isBold) {
      _fontStyle.fontWeight = '700';
    }
    if (_.has(row, 'compareAmount') && !_.isNil(row.compareAmount) && new Decimal(row.compareAmount).todp(0).toNumber() !== new Decimal(row.amount).todp(0).toNumber()) {
      this.props.setHasDifference({hasDifference: true});
    }
    return (
      <TableRow
        key={row._locatorId}
        hover={enableHover(row, this.props.report, this.props.flags.reportDetails, this.props.flags.reportDrillDownsScf)}
        sx={{
          '&.MuiTableRow-hover:hover .MuiTableCell-root': {
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor: GREY_90,
            cursor: 'pointer',
          },
          '&.MuiTableRow-hover:hover .MuiTypography-body1': {
            fontWeight: '600',
          },
        }}
        onClick={() => this.openPliDetails(row)}
      >
        <TableCell sx={{background: _bgColor, width: '100%', paddingLeft: `calc(2.5rem + ${row.indent}rem)`}} scope="row">
          <Typography variant={'body1'} {..._fontStyle}>{row.name}</Typography>
        </TableCell>
        {!row.config.isHeaderLine && (
          <React.Fragment>
            <TableCell align="right" sx={{background: _bgColor, minWidth: '15rem', maxWidth: '15rem', ..._borderStyle}} scope="row" >
              {this.renderAmountCell(row.amount, row.config.showCurrency, row.config.invertDisplayAmount, _fontStyle)}
            </TableCell>
            {((!_.isNil(this.props.comparePeriod) && !_.isEmpty(this.props.comparePeriod)) || (this.props.reviewMode && this.props.hasUnpublishedReports)) && (
              <TableCell align="right" sx={{background: _bgColor, minWidth: '15rem', maxWidth: '15rem', ..._borderStyle}} scope="row" >
                {this.renderAmountCell(row.compareAmount, row.config.showCurrency, row.config.invertDisplayAmount, _fontStyle)}
              </TableCell>
            )}
          </React.Fragment>
        )}
        {row.config.isHeaderLine && (
          <React.Fragment>
            <TableCell align="right" sx={{background: _bgColor, minWidth: '15rem', maxWidth: '15rem', ..._borderStyle}} scope="row" />
            {((!_.isNil(this.props.comparePeriod) && !_.isEmpty(this.props.comparePeriod)) || (this.props.reviewMode && this.props.hasUnpublishedReports)) && (
              <TableCell align="right" sx={{background: _bgColor, minWidth: '15rem', maxWidth: '15rem', ..._borderStyle}} scope="row" />
            )}
          </React.Fragment>
        )}
      </TableRow>
    )
  }

  renderRows = (rows) => {
    return _.map(rows, _row => {
      if (_row.config.hideLine && _row.children.length > 0) {
        return this.renderRows(_row.children);
      } else if (_row.config.isHeaderLine) {
        return (
          <React.Fragment key={_row._locatorId}>
            {this.renderRow(_row)}
            {this.renderRows(_row.children)}
          </React.Fragment>
        );
      } else if (!_row.config.isHeaderLine) {
        return (
          <React.Fragment key={_row._locatorId}>
            {this.renderRows(_row.children)}
            {this.renderRow(_row)}
          </React.Fragment>
        );
      } else {
        return null;
      }
    })
  }

  renderReport = (visible = true) => {
    if (!visible || _.isNil(this.props.report) || _.isNil(this.props.report.reportConfig) || this.props.loading || this.props.hasError) {
      return;
    }

    const _period = this.props.reportingPeriodMap[this.props.period];
    let _record = {};
    if (_.has(this.props, 'report.record') && !_.isNil(this.props.report.record)) {
      _record = this.props.report.record;
    }
    const {comparePeriod: _comparePeriod, compareRecord: _compareRecord} = this._getComparePeriod(_period);

    const _meta = _.get(this.props.report, 'reportFile.metadata', null);

    return (
      <Box width={'100%'}>
        <Stack direction={'column'} marginTop={'1.25rem'} flex={1}>
          <Stack spacing={1} alignItems={'center'} direction={'column'}>
            <Typography variant={'h3'} component={'div'}>
              {_.has(this.props.currentOrg, 'org_metadata.companyLegalName') ? this.props.currentOrg.org_metadata.companyLegalName : this.props.currentOrg.orgName}
            </Typography>
            <Typography variant={'h3'} component={'div'}>
              {this.props.title}
            </Typography>
            {this.renderDownloadButton()}
          </Stack>
          <ReportTable period={_period} comparePeriod={_comparePeriod} reviewMode={this.props.reviewMode} reportRecord={_record} compareReportRecord={_compareRecord} meta={_meta}>
            {this.renderRows(this.props.report.reportConfig, 0)}
          </ReportTable>
        </Stack>
        <ReportDetailsModal pli={this.state.pliDetails.pli} open={this.state.pliDetails.open} onClose={this.onClose} reportType={_record.reportType} period={_period} comparePeriod={_comparePeriod} currentFileId={_record.fileId} compareFileId={_.get(this.props, 'report.compareRecord.fileId', '')} meta={_meta} />
      </Box>
    )
  }

  renderReconReport = (visible = true) => {
    if (!visible || _.isNil(this.props.report) || _.isNil(this.props.report.reconReportConfig) || this.props.loading || this.props.hasError) {
      return;
    }

    const _reconReportConfig = this.props.report.reconReportConfig;
    const _reportData = [
      {
        name: `GAAP ${_reconReportConfig.gaapTotal.name}`,
        amount: _reconReportConfig.gaapTotal.amount,
        compareAmount: _reconReportConfig.gaapTotal.compareAmount,
      },
      ..._reconReportConfig.reconDetails,
      {
        name: `Non-GAAP ${_reconReportConfig.nonGaapTotal.name}`,
        amount: _reconReportConfig.nonGaapTotal.amount,
        compareAmount: _reconReportConfig.nonGaapTotal.compareAmount,
      },
    ]
    const _period = this.props.reportingPeriodMap[this.props.period];
    const {comparePeriod: _comparePeriod, compareRecord: _compareRecord} = this._getComparePeriod(_period);

    return (
      <React.Fragment>
        <Typography variant={'h3'} component={'div'} textAlign={'center'} marginTop={'1.5rem'}>
          {_reconReportConfig.title}
        </Typography>
        <ReportTable period={_period} comparePeriod={_comparePeriod} reviewMode={this.props.reviewMode} compareReportRecord={_compareRecord}>
          {_.map(_reportData, (_data, _idx) => {
            const _bgColor = _idx % 2 === 0 ? GREEN_70 : WHITE_100
            const _style = {};
            if (_idx === _reportData.length - 1) {
              _style.borderTop = '1px solid black';
              _style.borderBottom = '3px double black';
            }
            return (
              <TableRow
                key={_idx}
              >
                <TableCell sx={{background: _bgColor, width: '100%', paddingLeft: `${_.inRange(_idx, 1, _reportData.length - 1) ? 3.25 : 2.25}rem`}} scope="row">
                  <Typography variant={'body1'}>{_data.name}</Typography>
                </TableCell>
                <TableCell align="right" sx={{..._style, background: _bgColor, minWidth: '15rem', maxWidth: '15rem'}} scope="row" >
                  {this.renderAmountCell(_data.amount, _idx === 0 || _idx === _reportData.length - 1, false)}
                </TableCell>
                {_comparePeriod && !this.props.reviewMode && (
                  <TableCell align="right" sx={{..._style, background: _bgColor, minWidth: '15rem', maxWidth: '15rem'}} scope="row" >
                    {this.renderAmountCell(_data.compareAmount, _idx === 0 || _idx === _reportData.length - 1, false)}
                  </TableCell>
                )}
              </TableRow>
            )
          })}
        </ReportTable>
      </React.Fragment>
    );
  }

  renderSupplementalDisclosures = () => {
    if (!this.props.flags.supplementalDisclosuresReport || !_.has(this.props, 'report.supplementalDisclosures') || this.props.loading || this.props.hasError) {
      return;
    }

    const _supplementalDisclosuresConfig = this.props.report.supplementalDisclosures;
    const _reportData = _supplementalDisclosuresConfig.disclosureDetails;

    const _period = this.props.reportingPeriodMap[this.props.period];
    const {comparePeriod: _comparePeriod, compareRecord: _compareRecord} = this._getComparePeriod(_period);

    return (
      <React.Fragment>
        <Typography variant={'h3'} component={'div'} textAlign={'center'} marginTop={'1.5rem'}>
          {_supplementalDisclosuresConfig.title}
        </Typography>
        <ReportTable period={_period} comparePeriod={_comparePeriod} reviewMode={this.props.reviewMode} compareReportRecord={_compareRecord}>
          {_.map(_reportData, (_data, _idx) => {
            const _bgColor = _idx % 2 === 0 ? GREEN_70 : WHITE_100
            const _style = {};
            return (
              <TableRow
                key={_idx}
              >
                <TableCell sx={{background: _bgColor, width: '100%', paddingLeft: `${_.inRange(_idx, 1, _reportData.length - 1) ? 3.25 : 2.25}rem`}} scope="row">
                  <Typography variant={'body1'}>{_data.name}</Typography>
                </TableCell>
                <TableCell align="right" sx={{..._style, background: _bgColor, minWidth: '15rem', maxWidth: '15rem'}} scope="row" >
                  {this.renderAmountCell(_data.amount, _idx === 0 || _idx === _reportData.length - 1, _.get(_data, 'config.invertDisplayAmount', false))}
                </TableCell>
                {_comparePeriod && !this.props.reviewMode && (
                  <TableCell align="right" sx={{..._style, background: _bgColor, minWidth: '15rem', maxWidth: '15rem'}} scope="row" >
                    {this.renderAmountCell(_data.compareAmount, _idx === 0 || _idx === _reportData.length - 1, _.get(_data, 'config.invertDisplayAmount', false))}
                  </TableCell>
                )}
              </TableRow>
            )
          })}
        </ReportTable>
      </React.Fragment>
    );
  }

  render = () => {
    if (!this.props.visible) {
      return;
    }

    const _period = this.props.reportingPeriodMap[this.props.period];
    const _periodType = !_.isNil(_period) ? _period.periodType : null;

    console.log('ConfiguredReport render', {loading: this.props.loading, hasError: this.props.hasError, report: this.props.report});

    return (
      <Stack direction={'column'} height={`calc(100vh - ${APP_HEADER_HEIGHT} - ${FOOTER_HEIGHT} - (2 * 1.75rem))`}>
        <Loading loading={this.props.loading} message={'Loading reports'} />
        <Error hasError={this.props.hasError || (!_.isNil(this.props.report) && _.isNil(this.props.report.reportConfig))} message={'There was an error processing your request. Please try again later.'}/>
        <NoData hidden={this.props.loading || this.props.hasError || (!_.isNil(this.props.report) && !_.isNil(this.props.report.record))} screen={this.props.title} reportType={this.props.reportType} periodType={_periodType} />
        {this.renderReport()}
        {this.renderReconReport()}
        {this.renderSupplementalDisclosures()}
        {!_.isNil(this.props.report) && !_.isNil(this.props.report.reportConfig) && (
          <Box paddingY={'1rem'} display={'flex'} flex={'1'} justifyContent={'flex-end'}>
            <Typography variant={'caption'} align={'right'}>
              Report ID: {this.props.report.record.fileId}{!_.isNil(this.props.report.compareRecord) && (` / ${this.props.report.compareRecord.fileId}`)}
            </Typography>
          </Box>
        )}
      </Stack>
    )
  }
}

ConfiguredReportClass.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  hasError: PropTypes.bool.isRequired,
  reportingPeriodMap: PropTypes.object.isRequired,
  currentOrg: PropTypes.shape({
    orgId: PropTypes.string.isRequired,
    org_metadata: PropTypes.shape({
      companyLegalName: PropTypes.string,
      hideDownload: PropTypes.bool,
    }),
    orgName: PropTypes.string.isRequired,
  }),
  comparePeriod: PropTypes.string,
  accessToken: PropTypes.string.isRequired,
  period: PropTypes.string,
  report: PropTypes.shape({
    record: PropTypes.shape({
      reportType: PropTypes.string,
      filledTemplateExcelKey: PropTypes.string,
      fileId: PropTypes.string,
    }),
    reportFile: PropTypes.shape({
      data: PropTypes.object.isRequired,
      meta: PropTypes.object,
    }),
    reportConfig: PropTypes.array,
    reconReportConfig: PropTypes.object,
    supplementalDisclosures: PropTypes.object,
    compareRecord: PropTypes.shape({
      reportType: PropTypes.string,
      filledTemplateExcelKey: PropTypes.string,
      fileId: PropTypes.string,
    }),
  }),
  reportType: PropTypes.string.isRequired,
  flags: PropTypes.shape({
    reportDetails: PropTypes.bool.isRequired,
    reportDrillDownsScf: PropTypes.bool.isRequired,
    supplementalDisclosuresReport: PropTypes.bool.isRequired,
  }).isRequired,
  reviewMode: PropTypes.bool.isRequired,
  hasUnpublishedReports: PropTypes.bool.isRequired,
  setHasDifference: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    currentOrg: state.session.currentOrg,
    reportingPeriodMap: state.periods.reportingPeriodMap,
    hasUnpublishedReports: state.reports.hasUnpublishedReports,
  }
}

export default withLDConsumer()(connect(mapStateToProps, {setHasDifference})(withRequiredAuthInfo(withLogger(ConfiguredReportClass))));
