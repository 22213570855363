import {useCallback, useEffect} from 'react';

export function removeCommentHighlight (editor, highlightId) {
  let result = false;

  editor.state.doc.descendants((node, pos) => {
    if (node.isText || node.isInline) {
      node.marks.forEach((mark) => {
        const dataId = mark.attrs.highlightId;

        if (dataId === highlightId) {
          const from = pos;
          const to = pos + node.nodeSize;

          // Create and dispatch a transaction to remove the mark
          const transaction = editor.state.tr.removeMark(from, to, mark.type);
          editor.view.dispatch(transaction);
          result = true;
        }
      });
    }

    // handle cases where the Node itself is marked (linkedText)
    if (node.attrs.highlightId === highlightId) {
      const from = pos;
      const to = pos + node.nodeSize;

      const transaction = editor.state.tr.removeMark(from, to, editor.schema.marks.commentHighlight);
      editor.view.dispatch(transaction);
      result = true;
    }
  });

  return result;
}

// Get the mark and node of a comment highlight from `data-highlight-id`
export function getCommentHighlight (editor, highlightId) {
  let result = null;

  // Iterate over each node in the document
  editor.state.doc.descendants((node, pos) => {
    // Check if the node itself has the mark
    if (node.isText || node.isInline) {
      node.marks.forEach((mark) => {
        const dataId = mark.attrs.highlightId;

        if (dataId === highlightId) {
          result = {mark, node, pos};
        }
      });
    }

    // Additionally, handle cases where the node itself is marked (linkedText)
    if (node.attrs.highlightId === highlightId) {
      const mark = node.marks.find((m) => m.type.name === 'commentHighlight');
      if (mark) {
        result = {mark, node, pos};
      }
    }
  });

  return result;
}

export function getCommentHighlightContent (highlightId) {
  // First, try to find the mark by searching for an element with the matching data-highlight-id
  let elem = document.querySelector(`mark[data-highlight-id="${highlightId}"]`);

  if (!elem) {
    // If no mark is found, try to find a node element with the matching data-highlight-id
    elem = document.querySelector(`[data-highlight-id="${highlightId}"]`);
  }

  if (!elem) {
    return null;
  }

  // Traverse the DOM to find the text content inside the linked text node
  const linkedTextElem = elem.querySelector('.linked-text');

  if (linkedTextElem) {
    return linkedTextElem.innerHTML;
  }

  // Fallback to returning the entire innerHTML if linked text is not found
  return elem.innerHTML;
}

export const HIGHLIGHT_EVENT_NAME = 'commentHighlight';

// Trigger highlight active
export function highlightEvent (highlightId, isClick = false) {
  const event = new CustomEvent(HIGHLIGHT_EVENT_NAME, {
    detail: {highlightId, isClick},
  });

  document.documentElement.dispatchEvent(event);
}

export function useHighlightEvent () {
  return useCallback((highlightId) => {
    const event = new CustomEvent(HIGHLIGHT_EVENT_NAME, {
      detail: {highlightId},
    });

    document.documentElement.dispatchEvent(event);
  }, []);
}

export function useHighlightEventListener (callback) {
  useEffect(() => {
    function handler (event) {
      callback(event.detail.highlightId, event.detail.isClick);
    }

    document.documentElement.addEventListener(
      HIGHLIGHT_EVENT_NAME,
      handler,
    );

    return () => {
      document.documentElement.removeEventListener(
        HIGHLIGHT_EVENT_NAME,
        handler,
      );
    };
  }, [callback]);
}
