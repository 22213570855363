import {createApi} from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import store from '../store';
import _ from 'lodash';

const axiosBaseQuery =
  ({baseUrl} = {baseUrl: ''}) =>
    async ({url, method = 'GET', data, params, headers}) => {
      const {session} = store.getState();
      const _headers = {
        'x-inscope-org': session.currentOrg.orgId,
        ...headers,
      }
      if (!_.isNil(session.accessToken)) {
        _headers.Authorization = `Bearer ${session.accessToken}`;
      }
      try {
        const result = await axios({
          url: `${baseUrl}${url}`,
          method,
          data,
          params,
          headers: _headers,
        })
        return {data: result.data}
      } catch (axiosError) {
        const err = axiosError
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      }
    }

export const documentWorkspaceApiSlice = createApi({
  reducerPath: 'documentWorkspaceApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_DOCUMENT_WORKSPACE_API_URL,
  }),
  tagTypes: ['Documents'],
  endpoints: builder => ({
    getDocuments: builder.query({
      query: () => ({
        url: '/documents',
        method: 'GET',
      }),
      transformResponse: (response) => response.records,
      providesTags: (result) =>
        result
          ? _.map(result, ({documentId}) => ({type: 'Documents', id: documentId}))
          : ['Documents'],
    }),
    getDocument: builder.query({
      query: ({documentId}) => ({
        url: `/document/${documentId}`,
        method: 'GET',
      }),
      providesTags: (result, error, {documentId}) => ([{type: 'Documents', id: documentId}]),
    }),
    getDocumentCollabToken: builder.query({
      query: ({documentId}) => ({
        url: `/document/${documentId}/collabToken`,
        method: 'GET',
      }),
    }),
    createDocument: builder.mutation({
      query: (data) => ({
        url: '/documents',
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.record,
      invalidatesTags: ['Documents'],
    }),
    deleteDocument: builder.mutation({
      query: ({documentId}) => ({
        url: `/document/${documentId}`,
        method: 'PATCH',
        data: {
          isDeleted: 'true',
        },
      }),
      transformResponse: (response) => response.record,
      invalidatesTags: ['Documents'],
    }),
    markDocumentSaved: builder.mutation({
      query: ({documentId}) => ({
        url: `/document/${documentId}/save`,
        method: 'POST',
      }),
      transformResponse: (response) => response.record,
      invalidatesTags: (result, error, arg) => [{type: 'Documents', id: arg.document}],
    }),
    updateDocument: builder.mutation({
      query: ({documentId, data}) => ({
        url: `/document/${documentId}`,
        method: 'PATCH',
        data,
      }),
      transformResponse: (response) => response.record,
      invalidatesTags: (result, error, arg) => [{type: 'Documents', id: arg.documentId}],
    }),
  }),
});

export const {
  endpoints,
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useGetDocumentQuery,
  useGetDocumentCollabTokenQuery,
  useGetDocumentsQuery,
  useMarkDocumentSavedMutation,
  useUpdateDocumentMutation,
} = documentWorkspaceApiSlice;
