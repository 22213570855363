import React, {useState, useEffect} from 'react';
import {BubbleMenu} from '@tiptap/react';
import CommentToolbar from './CommentToolbar';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';

const bubbleMenuStyles = {
  background: '#fff',
  gap: '0.25rem',
  padding: '1px',
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
  border: '1px solid #DDE0DE',
  borderRadius: '8px',
  width: 104,
  height: 32,
};

const CommentSelectionMenu = ({editor}) => {
  const [rightClick, setRightClick] = useState(false);

  useEffect(() => {
    const handleContextMenu = (event) => {
      setRightClick(true);
    };

    const handleMouseDown = (event) => {
      if (rightClick) {
        setRightClick(false);
      }
    };

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [rightClick]);

  const shouldShowMenu = () => {
    return !rightClick &&
      shouldShowBubbleMenu(editor) &&
      !editor.storage.commentHighlight.showComposer &&
      !editor.isActive('commentHighlight');
  };

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{zIndex: 99, placement: 'top'}}
      shouldShow={() => shouldShowMenu()}
    >
      {shouldShowMenu()
        ? (
          <Box sx={bubbleMenuStyles}>
            <CommentToolbar editor={editor} />
          </Box>
          )
        : null
      }
    </BubbleMenu>
  );
}

CommentSelectionMenu.propTypes = {
  editor: PropTypes.object,
}

export default CommentSelectionMenu;

const shouldShowBubbleMenu = (editor) => {
  const {state} = editor;
  const {selection} = state;
  const {from, to} = selection;

  const selectedNode = editor.state.doc.nodeAt(from);
  if (selectedNode && selectedNode.type.name === 'table') {
    return false; // Do not show the menu if the selected node is a table
  }

  // Check if the selection covers a text or a node
  const isNodeSelected = !!selectedNode && selection.node && from === to - selectedNode.nodeSize;
  const selectedText = state.doc.textBetween(from, to, ' ');

  // Show the menu if there's selected text or a node is selected
  return selectedText !== '' || isNodeSelected;
};
