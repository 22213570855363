import React, {useState, useEffect, useRef, useMemo, useCallback} from 'react';
import {HotTable} from '@handsontable/react';
import './DocumentTableCellTypes';
import './DocumentTableStyles.css';
import {ContextMenu} from './ContextMenu';
import {handlePaste, handlePasteCleanup, simulatePasteEvent} from './PasteHandler';
import PropTypes from 'prop-types';
import spacetime from 'spacetime';
import _ from 'lodash';

import {Box, IconButton, Tooltip, Typography} from '@mui/material';
import TimeAgo from '../../TimeAgo';
import TableSkeleton from './TableSkeleton';
import TrashIcon from '../../../assets/trash-light.svg';
import LinkIcon from '../../../assets/link.svg';
import LinkBrokenIcon from '../../../assets/link-broken.svg';

import {NodeViewWrapper} from '@tiptap/react';

import {useInView} from 'react-intersection-observer';

import {useGetLinkedItemQuery} from '../../../data/excelApi';

// TABLE_WIDTH == 816 - 48 - 48 (left and right padding)
// MIN_LABEL + MIN_AMOUNT + SPACER + MIN_AMOUNT == TABLE_WIDTH
const TABLE_WIDTH = 720;
const MIN_LABEL_COL_WIDTH = 320;
const MIN_AMOUNT_COL_WIDTH = 154;
const SPACER_COL_WIDTH = 6;
const MIN_ROW_HEIGHT = 10;
const DEFAULT_ROW_HEIGHT = 11;

export const calcRowHeight = (multiplier) => {
  let _rowHeight = Math.floor(DEFAULT_ROW_HEIGHT * multiplier);
  if (_rowHeight < MIN_ROW_HEIGHT) {
    _rowHeight = MIN_ROW_HEIGHT;
  }

  return _rowHeight;
}

const DEFAULT_MERGED_CELLS = [{row: 0, col: 1, rowspan: 1, colspan: 3}];
const DEFAULT_SIZE_MULTIPLIER = 1;

const DocumentTable = ({editor, node, updateAttributes, getPos, selected, deleteNode, extension}) => {
  const tableRef = useRef(null);
  const {ref, inView} = useInView({triggerOnce: true});
  const isPrinting = extension.options.isPrinting;

  const isLinked = node.attrs['is-linked'];
  const linkedItemId = node.attrs['linked-item-id'];
  const {data: linkedItemData, isLoading: isLoadingLinkedData, isError} = useGetLinkedItemQuery({itemId: linkedItemId}, {
    skip: !isLinked || !linkedItemId || !inView,
  });

  const tableData = useMemo(
    () => (node.attrs['table-data'] ? JSON.parse(node.attrs['table-data'].replace(/\\'/g, "'")) : []),
    [node.attrs['table-data']],
  );

  const tableMeta = useMemo(
    () => (node.attrs['table-meta'] ? JSON.parse(node.attrs['table-meta']) : {}),
    [node.attrs['table-meta']],
  );

  const tableMergedCells = useMemo(
    () => (node.attrs['table-merged-cells'] ? JSON.parse(node.attrs['table-merged-cells']) : DEFAULT_MERGED_CELLS),
    [node.attrs['table-merged-cells']],
  );

  const tableSizeMultiplier = useMemo(
    () => JSON.parse(node.attrs['table-size-multiplier']),
    [node.attrs['table-size-multiplier']],
  );

  const updateNodeAttributes = (attributes) => {
    if (typeof getPos() === 'number') {
      updateAttributes(attributes);
    }
  };

  const [cellProperties, setCellProperties] = useState({});
  const [mergedCells, setMergedCells] = useState([]);
  const [multiplier, setMultiplier] = useState(tableSizeMultiplier || DEFAULT_SIZE_MULTIPLIER);
  const [linkedErrorState, setLinkedErrorState] = useState(null);

  useEffect(() => {
    if (isError) {
      setLinkedErrorState('API_ERROR');
    } else if (linkedItemData && linkedItemData.record.deleted === 'true') {
      setLinkedErrorState('ITEM_DELETED');
    } else if (linkedItemData && !isLoadingLinkedData && !isError) {
      if (tableFitsOnPage(linkedItemData)) {
        setLinkedErrorState(null);
        simulatePasteEvent(tableRef.current.hotInstance, linkedItemData);
      } else {
        deleteNode();
      }
    }
  }, [linkedItemData, isLoadingLinkedData, isError]);

  useEffect(() => {
    setMergedCells(tableMergedCells);
  }, [tableMergedCells]);

  useEffect(() => {
    handleAfterChange(null, 'edit');
  }, [cellProperties]);

  const tableFitsOnPage = (pasteData) => {
    const rowCount = typeof pasteData === 'object' && !Array.isArray(pasteData)
      ? pasteData.record?.recordValues?.length
      : pasteData.length;
    const tableHeight = 20 * rowCount;

    if (tableHeight >= 960) {
      alert('Paste can’t  be completed.\n\nThe content exceeds the height of a single page and can’t be pasted. Please select a smaller range and try again.')
      return false;
    }

    return true;
  };

  const updateCellProperties = useCallback((row, col, newProperties) => {
    setCellProperties(prevProperties => {
      const currentCellProperties = tableRef.current?.hotInstance?.getCellMeta(row, col);
      const mergedProperties = {
        ...currentCellProperties,
        ...prevProperties[`${row}-${col}`],
        ...newProperties,
      };

      return {
        ...prevProperties,
        [`${row}-${col}`]: mergedProperties,
      };
    });
  }, []);

  const updateCellPropertiesRef = useRef(updateCellProperties);

  useEffect(() => {
    updateCellPropertiesRef.current = updateCellProperties;
  }, [updateCellProperties]);

  const clearCellProperties = (row, col) => {
    setCellProperties(prevProperties => {
      const newProperties = {...prevProperties};
      const key = `${row}-${col}`;
      delete newProperties[key];
      return newProperties;
    });
  };

  useEffect(() => {
    const _hot = tableRef?.current?.hotInstance;
    if (!_.isNil(_hot)) {
      _.forEach(_hot.getCellsMeta(), _meta => {
        updateCellProperties(_meta.row, _meta.col, {multiplier});
      });
      _hot.updateSettings({
        rowHeights: calcRowHeight(multiplier),
      });
    }
  }, [multiplier]);

  const cells = (row, col) => {
    return cellProperties[`${row}-${col}`] || getInitialCellProperties(row, col);
  };

  const getInitialCellProperties = (row, col) => {
    const initialProperties = {
      multiplier,
    };

    if (col === 0) {
      if (row <= 1) {
        initialProperties.type = 'labelHeaderCell';
      } else {
        initialProperties.type = 'labelCell';
      }
    } else if (col % 2 === 0) {
      if (row === 0) {
        initialProperties.type = 'headerCell'
      } else {
        initialProperties.type = 'paddingCell';
      }
    } else {
      if (row <= 1) {
        initialProperties.type = 'headerCell';
      } else {
        initialProperties.type = 'valueCell';

        if (row === 2) {
          initialProperties.format = 'dollar';
        }

        if (row === tableData.length - 1) {
          initialProperties.totalCell = true;
          initialProperties.singleBorderTop = true;
          initialProperties.doubleBorderBottom = true;
          initialProperties.format = 'dollar';
        }
      }
    }

    return initialProperties;
  };

  const handleBeforePaste = (data, coords) => {
    if (!tableFitsOnPage(data)) return false;
    handlePaste(data, coords, updateCellProperties, isLinked);
  };

  const handleAfterPaste = (data, coords) => {
    handlePasteCleanup(data, coords, updateCellProperties, tableRef.current.hotInstance, setMergedCells);
  };

  const updateMergedHeaders = (addedColIndex) => {
    const hotInstance = tableRef.current.hotInstance;
    const totalColumns = hotInstance.countCols();

    const isOverlapping = mergedCells.some(cell => {
      return cell.col <= addedColIndex - 2 && cell.col + cell.colspan > addedColIndex - 2;
    });

    if (!isOverlapping) {
      const newMergedCell = {
        row: 0,
        col: addedColIndex - 1,
        rowspan: 1,
        colspan: totalColumns - addedColIndex + 1,
      };

      setMergedCells(prevMergedCells => [...prevMergedCells, newMergedCell]);
      hotInstance.updateSettings({mergeCells: [...mergedCells, newMergedCell]});
    }
  };

  const shiftCellPositionsUp = (removedRowIndex, amount) => {
    setCellProperties(prevProperties => {
      const newProperties = {};
      Object.keys(prevProperties).forEach(key => {
        const [row, col] = key.split('-').map(Number);
        const cellProps = {...prevProperties[key]};
        if (row > removedRowIndex) {
          cellProps.row -= amount;
          cellProps.visualRow -= amount;
          newProperties[`${row - amount}-${col}`] = cellProps;
        } else {
          newProperties[key] = cellProps;
        }
      });

      return newProperties;
    });
  };

  const shiftCellPositionsDown = (addedRowIndex, amount) => {
    setCellProperties(prevProperties => {
      const newProperties = {};
      Object.keys(prevProperties).forEach(key => {
        const [row, col] = key.split('-').map(Number);
        const cellProps = {...prevProperties[key]};
        if (row >= addedRowIndex) {
          cellProps.row += amount;
          cellProps.visualRow += amount;
          newProperties[`${row + amount}-${col}`] = cellProps;
        } else {
          newProperties[key] = cellProps;
        }
      });

      return newProperties;
    });
  };

  const shiftCellPositionsRight = (addedColIndex) => {
    setCellProperties(prevProperties => {
      const newProperties = {};
      Object.keys(prevProperties).forEach(key => {
        const [row, col] = key.split('-').map(Number);
        const cellProps = {...prevProperties[key]};
        if (col >= addedColIndex) {
          cellProps.col += col === 0 ? 1 : 2;
          cellProps.visualCol += col === 0 ? 1 : 2;
          newProperties[`${row}-${cellProps.col}`] = cellProps;
        } else {
          newProperties[key] = cellProps;
        }
      });

      return newProperties;
    });
  };

  const shiftCellPositionsLeft = (removedColIndex) => {
    setCellProperties(prevProperties => {
      const newProperties = {};
      Object.keys(prevProperties).forEach(key => {
        const [row, col] = key.split('-').map(Number);
        const cellProps = {...prevProperties[key]};
        if (col > removedColIndex) {
          cellProps.col -= col === 0 ? 1 : 2;
          cellProps.visualCol -= col === 0 ? 1 : 2;
          newProperties[`${row}-${cellProps.col}`] = cellProps;
        } else if (_.isNil(cellProps.col) || !_.isFinite(cellProps.col)) {
          // No-op
        } else {
          newProperties[key] = cellProps;
        }
      });

      return newProperties;
    });
  };

  const _createMultiplier = () => {
    const _hot = tableRef.current.hotInstance;
    if (_.isNil(_hot)) {
      return 1;
    }

    let _multiplier = 1;
    const _numExtraCols = _hot.countCols() - 1;
    if (_numExtraCols > 3) {
      const _numAmountCols = Math.ceil(_numExtraCols / 2);
      const _numSpacerCols = Math.floor(_numExtraCols / 2);
      _multiplier = TABLE_WIDTH / (MIN_LABEL_COL_WIDTH + (_numAmountCols * MIN_AMOUNT_COL_WIDTH) + (_numSpacerCols * SPACER_COL_WIDTH));
    }
    setMultiplier(_multiplier);
  }

  const handleAfterCreateCol = (index) => {
    updateMergedHeaders(index);
    shiftCellPositionsRight(index);
    _createMultiplier();
  };

  const handleAfterRemoveCol = (index) => {
    if (index === 2) {
      handleAfterChange(null, 'edit');
    } else {
      setMergedCells(prevMergedCells => {
        return prevMergedCells.map(cell => {
          if (cell.col >= index) {
            // Decrease colspan by 1 for cells that are affected by the removal
            return {...cell, colspan: cell.colspan - 1};
          }
          return cell;
        }).filter(cell => cell.colspan > 0 && cell.col + cell.colspan <= tableRef.current.hotInstance.countCols());
      });
    }

    shiftCellPositionsLeft(index % 2 === 0 ? index + 1 : index);
    _createMultiplier();
  };

  const handleAfterCreateRow = (index, amount, source) => {
    if (source === 'populateFromArray' || source === 'CopyPaste.paste') return;
    shiftCellPositionsDown(index, amount);

    const hotInstance = tableRef.current.hotInstance;

    if (index === 2) {
      _.forEach(_.range(0, hotInstance.countCols()), _col => {
        updateCellProperties(index + 1, _col, {format: null, placeholder: null});
      });
    }

    const lastRow = hotInstance.countRows() - 1;
    clearCellProperties(lastRow - 1, 1);
    clearCellProperties(lastRow - 1, 3);
    hotInstance.render();
  };

  const handleAfterRemoveRow = (index, amount, source) => {
    if (source === 'populateFromArray') return;
    shiftCellPositionsUp(index, amount);

    const hotInstance = tableRef.current.hotInstance;

    if (index === 2) {
      _.forEach(_.range(0, hotInstance.countCols()), _col => {
        updateCellProperties(index, _col, {format: 'dollar', placeholder: _col === 0 ? 'Row description' : null});
      });
    }
  };

  const handleBeforeUnmergeCells = (cellRange) => {
    // clear adjacent data when unmerging a header cell,
    // otherwise paddingCells with inherit data when they shouldn't
    if (cellRange.from.row === 1) {
      const hotInstance = tableRef.current.hotInstance;
      const startCol = cellRange.from.col;
      const endCol = cellRange.to.col;

      for (let col = startCol; col <= endCol; col++) {
        hotInstance.setDataAtCell(1, col, '');
      }
    }
  };

  const handleAfterMergeCells = (cellRange, mergeParent) => {
    // Function to check if two merged cells overlap
    const doMergedCellsOverlap = (cell1, cell2) => {
      return cell1.row <= cell2.row + cell2.rowspan - 1 &&
        cell1.row + cell1.rowspan - 1 >= cell2.row &&
        cell1.col <= cell2.col + cell2.colspan - 1 &&
        cell1.col + cell1.colspan - 1 >= cell2.col;
    };

    // Function to merge two overlapping cells
    const mergeOverlappingCells = (cell1, cell2) => {
      return {
        row: Math.min(cell1.row, cell2.row),
        col: Math.min(cell1.col, cell2.col),
        rowspan: Math.max(cell1.row + cell1.rowspan - 1, cell2.row + cell2.rowspan - 1) - Math.min(cell1.row, cell2.row) + 1,
        colspan: Math.max(cell1.col + cell1.colspan - 1, cell2.col + cell2.colspan - 1) - Math.min(cell1.col, cell2.col) + 1,
      };
    };

    // Check if the merged cell is already in the mergedCells state
    const isAlreadyMerged = mergedCells.some(config =>
      config.row === mergeParent.row &&
      config.col === mergeParent.col &&
      config.rowspan === mergeParent.rowspan &&
      config.colspan === mergeParent.colspan,
    );

    if (!isAlreadyMerged) {
      // Check for overlapping cells and merge them
      const updatedMergedCellsConfig = mergedCells.map(cell => {
        if (doMergedCellsOverlap(cell, mergeParent)) {
          return mergeOverlappingCells(cell, mergeParent);
        }
        return cell;
      }).filter(cell => !doMergedCellsOverlap(cell, mergeParent));

      // Add the new merged cell if it doesn't overlap with any existing merged cells
      if (!updatedMergedCellsConfig.some(cell => doMergedCellsOverlap(cell, mergeParent))) {
        updatedMergedCellsConfig.push(mergeParent);
      }

      setMergedCells(updatedMergedCellsConfig);
    }
  };

  const handleAfterUnmergeCells = (cellRange) => {
    const updatedMergedCellsConfig = mergedCells.filter(mergedCell => {
      const isUnmerged = mergedCell.row === cellRange.from.row && mergedCell.col === cellRange.from.col;
      return !isUnmerged;
    });

    setMergedCells(updatedMergedCellsConfig);
  };

  const handleBeforeKeyDown = (event) => {
    const hotInstance = tableRef.current.hotInstance;
    const selection = hotInstance.getSelected();
    if (selection) {
      const currentRow = selection[0][0];
      const currentCol = selection[0][1];

      if (
        ['ArrowLeft', 'ArrowRight'].includes(event.key) &&
        currentCol % 2 !== 0 &&
        currentCol !== 0
      ) {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();

        if (event.key === 'ArrowLeft') {
          if (currentCol > 1) {
            hotInstance.selectCell(currentRow, currentCol - 1);
          }
        } else if (event.key === 'ArrowRight') {
          hotInstance.selectCell(currentRow, currentCol + 1);
        }
      }
    }
  };

  const handleDeleteTable = useCallback(() => {
    deleteNode();
  }, [deleteNode]);

  let firstMeasurement = true;
  const handleAfterChange = (changes, source) => {
    const hotInstance = tableRef?.current?.hotInstance;
    if (!hotInstance) { return; }

    const currentHeight = hotInstance.view?._wt?.wtTable?.holder?.clientHeight || 122;

    if (source === 'edit') {
      changes?.forEach(([row, prop, oldValue, newValue]) => {
        if (/^\s?\$/.test(newValue)) {
          hotInstance.setDataAtCell(row, prop, newValue.replace('$', ''));
          updateCellProperties(row, prop, {format: 'dollar'});
        } else if (/%\s?$/.test(newValue)) {
          hotInstance.setDataAtCell(row, prop, newValue.replace('%', ''));
          updateCellProperties(row, prop, {format: 'percent'});
        }
      });
    }

    if (firstMeasurement) {
      firstMeasurement = false;
      return; // Ignore the first inflated height
    }

    if (source === 'edit' || source === 'CopyPaste.paste') {
      updateNodeAttributes({
        'table-data': JSON.stringify(hotInstance.getData()).replace(/'/g, "\\'"),
        'table-meta': JSON.stringify(cellProperties),
        'table-merged-cells': JSON.stringify(mergedCells),
        'table-size-multiplier': JSON.stringify(multiplier),
        'table-height': currentHeight,
        'is-linked': isLinked,
      });
    }
  };

  // https://handsontable.com/docs/javascript-data-grid/api/options/
  const TWO_PERIOD_CONFIG = {
    afterInit: function () {
      const allCellsMeta = this.getCellsMeta();
      const newCellProperties = {};

      const persistedProps = ['format', 'doubleBorderBottom', 'singleBorderBottom', 'doubleBorderTop', 'singleBorderTop', 'cleared'];

      allCellsMeta.forEach(cellMeta => {
        const key = `${cellMeta.row}-${cellMeta.col}`;
        const newCellMeta = {...cellMeta};

        persistedProps.forEach(prop => {
          if (tableMeta[key] && Object.prototype.hasOwnProperty.call(tableMeta[key], prop)) {
            newCellMeta[prop] = tableMeta[key][prop];
          }
        });

        newCellProperties[key] = newCellMeta;
      });

      this.updateCellProperties = updateCellPropertiesRef.current;
      this.isLinked = isLinked;
      setCellProperties(newCellProperties);
    },
    afterChange: handleAfterChange,
    afterCreateCol: handleAfterCreateCol,
    afterRemoveCol: handleAfterRemoveCol,
    afterCreateRow: handleAfterCreateRow,
    afterRemoveRow: handleAfterRemoveRow,
    afterMergeCells: handleAfterMergeCells,
    afterUnmergeCells: handleAfterUnmergeCells,
    afterPaste: handleAfterPaste,
    autoWrapCol: true,
    autoWrapRow: true,
    beforeUnmergeCells: handleBeforeUnmergeCells,
    beforeKeyDown: handleBeforeKeyDown,
    beforePaste: handleBeforePaste,
    cell: [
      {
        row: 2,
        col: 0,
        placeholder: 'Row description',
      },
    ],
    cells,
    className: 'htLeft htBottom',
    contextMenu: editor.isEditable && ContextMenu,
    customBorders: true,
    data: tableData,
    fillHandle: false,
    fixedRowsTop: 1,
    height: 'auto',
    licenseKey: 'non-commercial-and-evaluation',
    manualRowMove: true,
    width: '100%',
    mergeCells: tableMergedCells || mergedCells,
    // minCols: 2,
    // minRows: 4,
    colWidths: function (colIdx) {
      const hotInstance = tableRef.current?.hotInstance;
      let colWidth = MIN_LABEL_COL_WIDTH;
      const minLabelWidth = MIN_LABEL_COL_WIDTH;
      let _numAmountCols = 2;
      let _numSpacerCols = 1;
      if (hotInstance) {
        const _numExtraCols = hotInstance.countCols() - 1;
        if (_numExtraCols > 0) {
          _numAmountCols = Math.ceil(_numExtraCols / 2);
          _numSpacerCols = Math.floor(_numExtraCols / 2);
        }
      }

      if (colIdx === 0) {
        if (hotInstance) {
          colWidth = Math.floor(Math.max(TABLE_WIDTH - (_numAmountCols * MIN_AMOUNT_COL_WIDTH) - (_numSpacerCols * SPACER_COL_WIDTH), minLabelWidth) * multiplier);
        } else {
          colWidth = Math.floor(Math.max(colWidth, minLabelWidth) * multiplier);
        }
      } else if (colIdx % 2 !== 0) {
        colWidth = Math.floor(MIN_AMOUNT_COL_WIDTH * multiplier) - 1;
      } else {
        colWidth = 5;
      }

      return colWidth;
    },
    persistentState: true,
    readOnly: !editor.isEditable || isLinked,
    rowHeaders: false,
    rowHeights: calcRowHeight(multiplier),
    tabNavigation: false,
  };

  return (
    <NodeViewWrapper ref={ref}>
      <Box sx={{position: 'relative'}}>
        <Box>
          {((inView || isPrinting) && !isLoadingLinkedData)
            ? (
                <HotTable
                  ref={tableRef}
                  settings={TWO_PERIOD_CONFIG}
                />
              )
            : (
                <TableSkeleton
                  isLinked={isLinked}
                  height={node.attrs['table-height'] || 122}
                />
              )
          }
        </Box>

        {inView && isLinked && !isLoadingLinkedData &&
          <Box>
            {linkedErrorState
              ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    top: 36,
                    left: -200,
                  }}>
                  <Typography fontSize={12} fontWeight={500} color="#EB5757" mr={2} mt={1}>
                    Data not found
                  </Typography>
                  <Box
                    sx={{
                      padding: '8px',
                      borderRadius: '4px',
                      zIndex: 161,
                      backgroundColor: '#FFCCCC',
                    }}
                  >
                    <img width={16} height={16} src={LinkBrokenIcon} alt="Linked table error" />
                  </Box>
                </Box>
                )
              : (
                <Tooltip
                  title={
                    <>
                      <Typography fontSize={12} fontWeight={500} color="#0F1E24">
                        Linked source: {linkedItemData?.record?.label}
                      </Typography>
                      {linkedItemData?.record?.updatedAt &&
                        <Typography fontSize={11} color="#0F1E24">
                          Synced <TimeAgo timestamp={spacetime(linkedItemData.record.updatedAt).epoch} /> by {linkedItemData?.record?.updatedBy || 'N/A'}
                        </Typography>
                      }
                    </>
                  }
                  placement="top"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.20)',
                        px: 1.5,
                        py: 1,
                        maxWidth: 'none',
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 36,
                      left: -96,
                      padding: '8px',
                      border: '1px solid #DDE0DE',
                      borderRadius: '4px',
                      zIndex: 161,
                      '&:hover': {
                        backgroundColor: '#E0E4E2',
                      },
                    }}
                  >
                    <img width={16} height={16} src={LinkIcon} alt="Linked table" />
                  </Box>
                </Tooltip>
                )
            }
          </Box>
        }

        {editor.isEditable && inView &&
          <IconButton
            sx={{
              position: 'absolute',
              top: 36,
              right: -36,
              padding: '4px',
              border: '1px solid #DDE0DE',
              borderRadius: '6px',
              zIndex: 161,
              '&:hover': {
                backgroundColor: '#E0E4E2',
              },
            }}
            onClick={handleDeleteTable}
          >
            <img width={12} height={12} src={TrashIcon} alt="Delete table" />
          </IconButton>
        }
      </Box>
    </NodeViewWrapper>
  );
};

DocumentTable.propTypes = {
  editor: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  updateAttributes: PropTypes.func.isRequired,
  getPos: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  deleteNode: PropTypes.func.isRequired,
  extension: PropTypes.shape({
    options: PropTypes.shape({
      isPrinting: PropTypes.bool,
    }),
  }),
};

export default React.memo(DocumentTable);
