// Original Source: https://github.com/ueberdosis/tiptap/issues/1036
// Has been extended/modified
import {Extension} from '@tiptap/core';
import {TextSelection, AllSelection} from '@tiptap/pm/state';

const SPACES_PER_TAB = 8;

export const clamp = (val, min, max) => {
  if (val < min) {
    return min;
  }
  if (val > max) {
    return max;
  }
  return val;
};

const IndentProps = {
  min: 0,
  max: 720,
  more: 30,
  less: -30,
};

export function isBulletListNode (node) {
  return node.type.name === 'bullet_list';
}

export function isOrderedListNode (node) {
  return node.type.name === 'order_list';
}

export function isTodoListNode (node) {
  return node.type.name === 'todo_list';
}

export function isListNode (node) {
  return isBulletListNode(node) ||
    isOrderedListNode(node) ||
    isTodoListNode(node);
}

function setNodeIndentMarkup (tr, pos, delta) {
  if (!tr.doc) return tr;

  const node = tr.doc.nodeAt(pos);
  if (!node) return tr;

  const minIndent = IndentProps.min;
  const maxIndent = IndentProps.max;

  const indent = clamp(
    (node.attrs.indent || 0) + delta,
    minIndent,
    maxIndent,
  );

  if (indent === node.attrs.indent) return tr;

  const nodeAttrs = {
    ...node.attrs,
    indent,
  };

  return tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks);
}

const updateIndentLevel = (tr, delta) => {
  const {doc, selection} = tr;

  if (!doc || !selection) return tr;

  if (!(selection instanceof TextSelection || selection instanceof AllSelection)) {
    return tr;
  }

  const {from, to} = selection;

  doc.nodesBetween(from, to, (node, pos) => {
    const nodeType = node.type;

    if (nodeType.name === 'paragraph' || nodeType.name === 'heading') {
      tr = setNodeIndentMarkup(tr, pos, delta);
      return false;
    }
    if (isListNode(node)) {
      return false;
    }
    return true;
  });

  return tr;
};

const insertTabCharacter = (tr, selection) => {
  const {from} = selection;
  const spacesToInsert = SPACES_PER_TAB;

  tr.insertText(' '.repeat(spacesToInsert), from);
  tr.setSelection(TextSelection.create(tr.doc, from + spacesToInsert));
  return tr;
};

const removeTabCharacter = (tr, selection) => {
  const {from, to} = selection;
  const nodeBefore = tr.doc.resolve(from).nodeBefore;
  const nodeText = nodeBefore?.text || '';

  if (nodeBefore && nodeBefore.isText && nodeText.endsWith(' '.repeat(SPACES_PER_TAB))) {
    tr.insertText('', from - SPACES_PER_TAB, to);
    tr.setSelection(TextSelection.create(tr.doc, from - SPACES_PER_TAB));
  } else {
    tr.delete(from - 1, to);
    tr.setSelection(TextSelection.create(tr.doc, from - 1));
  }
  return tr;
};

const splitNodeAndIndent = (tr, state) => {
  const {selection, schema} = state;
  const {$from} = selection;
  const node = $from.node($from.sharedDepth);

  // Check if the node is a valid text node
  if (node && node.isText) {
    // Split the text node at the cursor position
    const offset = $from.parentOffset;
    const newNode = node.cut(0, offset);
    const oldNode = node.cut(offset);

    // Insert the tab character at the cursor position
    const tabNode = schema.text('\t');
    tr.replaceWith($from.before(), $from.after(), [
      newNode,
      tabNode,
      oldNode,
    ]);
  } else if (node) {
    // If not a text node, split the node and insert the tab character
    tr.split($from.pos);
    tr = insertTabCharacter(tr, tr.selection);
  } else {
    // If the node is undefined, insert the tab character at the cursor position
    tr = insertTabCharacter(tr, tr.selection);
  }

  return tr;
};

export const Indent = Extension.create({
  name: 'indent',

  addOptions () {
    return {
      types: ['heading', 'paragraph'],
      defaultIndentLevel: 0,
    }
  },

  addGlobalAttributes () {
    return [
      {
        types: this.options.types,
        attributes: {
          indent: {
            default: this.options.defaultIndentLevel,
            renderHTML: attributes => ({
              style: `margin-left: ${attributes.indent}px!important;`,
            }),
            parseHTML: element => parseInt(element.style.marginLeft) || this.options.defaultIndentLevel,
          },
        },
      },
    ];
  },

  addCommands () {
    return {
      addTab: () => ({tr, state, dispatch, editor}) => {
        const {selection} = state;
        tr = insertTabCharacter(tr, selection);

        if (tr.docChanged) {
          dispatch && dispatch(tr);
          return true;
        }

        editor.chain().focus().run();
        return false;
      },
      removeTab: () => ({tr, state, dispatch, editor}) => {
        const {selection} = state;
        tr = removeTabCharacter(tr, selection);

        if (tr.docChanged) {
          dispatch && dispatch(tr);
          return true;
        }

        editor.chain().focus().run();
        return false;
      },
      indent: () => ({tr, state, dispatch, editor}) => {
        const {selection} = state;
        tr = tr.setSelection(selection);
        tr = updateIndentLevel(tr, IndentProps.more);

        if (tr.docChanged) {
          dispatch && dispatch(tr);
          return true;
        }

        editor.chain().focus().run();
        return false;
      },
      outdent: () => ({tr, state, dispatch, editor}) => {
        tr = updateIndentLevel(tr, IndentProps.less);

        if (tr.docChanged) {
          dispatch && dispatch(tr);
          return true;
        }

        editor.chain().focus().run();
        return false;
      },
      splitNodeAndIndent: () => ({tr, state, dispatch, editor}) => {
        tr = splitNodeAndIndent(tr, state);

        if (tr.docChanged) {
          dispatch && dispatch(tr);
          return true;
        }

        editor.chain().focus().run();
        return false;
      },
    };
  },

  addKeyboardShortcuts () {
    return {
      Tab: ({editor}) => {
        if (!(this.editor.isActive('bulletList') || this.editor.isActive('orderedList'))) {
          const {state} = editor;
          const {selection} = state;
          const {from, to, $from} = selection;

          if (from !== to) {
            // If there's a selection, indent the selected nodes
            return this.editor.commands.indent();
          }

          const pos = $from.pos;

          if (pos === $from.start()) {
            return this.editor.commands.indent();
          } else if (pos === $from.end()) {
            // Cursor is at the end of the node
            return this.editor.commands.addTab();
          } else {
            // Cursor is somewhere in the middle of the node
            return this.editor.commands.splitNodeAndIndent();
          }
        }
      },
      'Shift-Tab': () => {
        if (!(this.editor.isActive('bulletList') || this.editor.isActive('orderedList'))) return this.editor.commands.outdent();
      },
      Backspace: ({editor}) => {
        const {state} = editor;
        const {selection} = state;
        const {from, to} = selection;

        if (from !== to) {
          editor.commands.deleteSelection();
          return true;
        }

        const nodeBefore = state.tr.doc.resolve(from).nodeBefore;
        const nodeText = nodeBefore?.text || '';

        if (nodeBefore && nodeBefore.isText && nodeText.endsWith(' '.repeat(SPACES_PER_TAB))) {
          return this.editor.commands.removeTab();
        }

        if (nodeBefore && nodeBefore.isText && nodeBefore.text.length > 0) {
          return false;
        }

        return this.editor.commands.outdent();
      },
    };
  },
});
